import "./App.css";
import Navbar from "./components/common/Navbar";
import Sidebar from "./components/common/Sidebar";
import VaultDetails from "./components/pages/VaultDetails";
import Vaults from "./components/pages/Vaults";
import {useState} from "react";
import {Routes, Route} from "react-router-dom";
import {createConfig, WagmiConfig} from "wagmi";
import {polygonMumbai, arbitrumGoerli, goerli} from "wagmi/chains";
import { ConnectKitProvider, getDefaultConfig } from "connectkit";

const wagmiConfig = createConfig(
    getDefaultConfig({
        // Required API Keys
        infuraId: 'c9555cd3ddbb47ec9378e98c8af5415e',
        chains: [polygonMumbai, arbitrumGoerli, goerli],
        walletConnectProjectId: '786022e7fcdf323926afe2866d1f71f4',

        // Required
        appName: "UpYield Finance",

        // Optional
        appDescription: "Your App Description",
        appUrl: "https://family.co", // your app's url
        appLogo: "https://family.co/logo.png", // your app's logo,no bigger than 1024x1024px (max. 1MB)
    }),
);

function App() {
    const [isSidebarShow, setSidebarShow] = useState(false);

    return (
        <>
            <WagmiConfig config={wagmiConfig}>
                <ConnectKitProvider debugMode>
                <div>
                    <Navbar isSidebarShow={isSidebarShow} setSidebarShow={setSidebarShow}/>
                    <Sidebar setSidebarShow={setSidebarShow} isSidebarShow={isSidebarShow}/>
                    <Routes>
                        <Route path="/" element={<Vaults/>}/>
                        <Route path="/vaults/:productId" element={<VaultDetails/>}/>
                    </Routes>
                </div>
                </ConnectKitProvider>
            </WagmiConfig>
        </>
    );
}

export default App;

import React from "react";
import {useState} from "react";
import DepositTab from "./DepositTab";
import WithdrawTab from "./WithdrawTab";

function DepositPanel({product, network, address, refresh}) {
    const [activeTab, setActiveTab] = useState("DEPOSIT");

    return (
        <>
            <div className="border-2  border-[#2F4858]    bg-primary rounded-lg flex flex-col w-full lg:max-w-[434px]">
                <div className="flex flex-row">
                    <button
                        onClick={() => setActiveTab("DEPOSIT")}
                        className={`${
                            activeTab === "DEPOSIT"
                                ? " font-bold text-white after:absolute after:w-full after:h-[4px] after:contents-[]  bg-gredient after:left-0 after:-top-0.5 after:rounded-t-md border-x border-b-0 "
                                : "   "
                        }   text-sm text-[#78909C]  relative font-medium w-1/2 py-3 border-b  border-[#2F4858]`}
                    >
                        DEPOSIT
                    </button>
                    <button
                        onClick={() => setActiveTab("WITHDRAW")}
                        className={`${
                            activeTab === "WITHDRAW"
                                ? " font-bold text-white after:absolute after:w-full after:h-[4px] after:contents-[]  bg-gredient after:left-0 after:-top-0.5 after:rounded-t-md border-x border-b-0 "
                                : "   "
                        }   text-sm text-[#78909C]  relative w-1/2 py-3 border-b  border-[#2F4858] `}
                    >
                        WITHDRAW
                    </button>
                </div>
                {" "}
                {activeTab === "DEPOSIT" && (<DepositTab product={product} network={network} address={address} refresh={refresh}/>)}
                {activeTab === "WITHDRAW" && (<WithdrawTab product={product} network={network} address={address}/>)}
            </div>
        </>
    );
}

export default DepositPanel;

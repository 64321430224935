import React from "react";
import {useState} from "react";
import {usePrepareContractWrite, useContractWrite, useContractReads, useWaitForTransaction} from 'wagmi'
import {vaultContractAddresses, vaultAbi} from "../../config";
import {useDebounce} from '../../hooks/useDebounce';
import {parseEther, formatEther} from 'viem'

function WithdrawTab({product, network, address}) {
    // the amount of assets that will be deposited
    const [withdrawAmount, setWithdrawAmount] = useState('')
    const [requestedWithdrawal, setRequestedWithdrawal] = useState(0.0)
    const [unlockedAssets, setUnlockedAssets] = useState(0.0)
    const debouncedWithdrawAmount = useDebounce(`${Number(withdrawAmount)}`, 500)
    const vaultContractAddress = network ? vaultContractAddresses[network][product.productId] : undefined;

    const isRequestEnabled = Boolean(Number(debouncedWithdrawAmount)) && (requestedWithdrawal < Number(debouncedWithdrawAmount));
    const isWithdrawalEnabled = Boolean(Number(debouncedWithdrawAmount)) && (requestedWithdrawal >= Number(debouncedWithdrawAmount));
    const isCancelEnabled = Number(requestedWithdrawal) > 0;

    console.log(`WithdrawTab: network: ${network}, address: ${address}, vaultContractAddress: ${vaultContractAddress}`);

    // calls vault.withdrawQueueBalance() and vault.totalUnlockedAssets()
    useContractReads({
        contracts: [
            {
                address: vaultContractAddress,
                abi: vaultAbi,
                functionName: 'totalWithdrawQueueBalance'
            },
            {
                address: vaultContractAddress,
                abi: vaultAbi,
                functionName: 'totalUnlockedAssets'
            }
        ],
        enabled: vaultContractAddress,
        onSuccess(data) {
            const withdrawQueueBalance = formatEther(data[0].result);
            setRequestedWithdrawal(Number(withdrawQueueBalance));
            const totalUnlockedAssets = formatEther(data[1].result);
            setUnlockedAssets(Number(totalUnlockedAssets));
            console.log(`Read from vault contract: withdrawQueueBalance: ${withdrawQueueBalance}, totalUnlockedAssets: ${totalUnlockedAssets}. data`,data);
        },
        onError(error) {
            console.log('Error on reading from vault contract', error)
        }
    })

    // calls function withdraw(uint256 amount, address receiver, address owner)
    const preparedWithdrawFunction = usePrepareContractWrite({
        address: vaultContractAddress,
        abi: vaultAbi,
        functionName: 'withdraw',
        enabled: vaultContractAddress && isWithdrawalEnabled,
        args: [parseEther(debouncedWithdrawAmount), address, address],
        onSuccess(data) {
            console.log('Withdraw result: ', data);
        },
        onError(error) {
            console.log('Error on asset withdrawal', error)
        }
    });
    const withdrawFunction = useContractWrite(preparedWithdrawFunction.config);
    const {isLoading: isWithdrawing, isSuccess: isWithdrawn} = useWaitForTransaction({
        hash: withdrawFunction.data?.hash
    });

    // calls function requestWithdraw(uint256 amount)
    const preparedRequestWithdrawFunction = usePrepareContractWrite({
        address: vaultContractAddress,
        abi: vaultAbi,
        functionName: 'requestWithdraw',
        enabled: vaultContractAddress,
        args: [parseEther(debouncedWithdrawAmount)],
        onSuccess(data) {
            console.log('requestWithdraw result: ', data);
        },
        onError(error) {
            console.log('Error on requestWithdraw', error)
        }
    });
    const requestWithdrawalFunction = useContractWrite(preparedRequestWithdrawFunction.config);
    const {isLoading: isRequestingWithdrawal, isSuccess: isReadyForWithdrawal} = useWaitForTransaction({
        hash: requestWithdrawalFunction.data?.hash,
    });

    // calls function cancelWithdrawal(uint256 amount)
    const preparedCancelWithdrawFunction = usePrepareContractWrite({
        address: vaultContractAddress,
        abi: vaultAbi,
        functionName: 'cancelWithdrawal',
        enabled: vaultContractAddress && isCancelEnabled,
        args: [],
        onSuccess(data) {
            console.log('cancelWithdrawal result: ', data);
        },
        onError(error) {
            console.log('Error on cancelWithdrawal', error)
        }
    });
    const cancelWithdrawalFunction = useContractWrite(preparedCancelWithdrawFunction.config);
    const {isLoading: isCancellingWithdrawal, isSuccess: isWithdrawalCancelled} = useWaitForTransaction({
        hash: cancelWithdrawalFunction.data?.hash,
    });

    // handle buttons state
    let withdrawButtonText = 'Withdraw';
    let requestButtonText = 'Request Withdrawal';
    let cancelButtonText = 'Cancel Request';
    let isWithdrawButtonDisabled = !Number(withdrawAmount) || !isWithdrawalEnabled || isRequestEnabled;
    let isRequestButtonDisabled = !Number(withdrawAmount);
    let isCancelButtonDisabled = !isCancelEnabled;

    if (isRequestingWithdrawal) {
        requestButtonText = 'Requesting...';
        isRequestButtonDisabled = true;
    } else if (isReadyForWithdrawal) {
        requestButtonText = 'Requested';
    }
    if (isWithdrawing) {
        withdrawButtonText = 'Withdrawing...';
        isWithdrawButtonDisabled = true;
    } else if (isWithdrawn) {
        withdrawButtonText = 'Withdrawn';
    }
    if (isCancellingWithdrawal) {
        cancelButtonText = 'Cancelling...';
        isCancelButtonDisabled = true;
    } else if (isWithdrawalCancelled) {
        cancelButtonText = 'Cancelled';
    }

    console.log(`INFO: amount: ${debouncedWithdrawAmount}, requestedWithdrawal: ${requestedWithdrawal}, isRequestNeeded: ${isRequestEnabled}, isWithdrawalEnabled: ${isWithdrawalEnabled}, isCancelEnabled: ${isCancelEnabled}`);

    return (
        <>
            <div className="sm:mx-10 mx-5 mb-6 py-6">
                <div className="flex flex-row items-center">
                    <input
                        type="text"
                        className="text-md px-6 py-3 font-bold text-white opacity-80  text-end w-full rounded-lg border border-[#203746] bg-[#02121D] placeholder:text-white placeholder:opacity-80"
                        placeholder={'0.00'}
                        value={withdrawAmount}
                        onChange={(e) => setWithdrawAmount(e.target.value)}
                    />
                    <div className="text-end text-sm text-white pt-1 opacity-80 font-bold px-3">
                        {product.vaultAsset}
                    </div>
                </div>

                <div
                    className="text-end text-sm text-white pt-3 opacity-80 font-bold px-3">Requested: {requestedWithdrawal} {product.vaultAsset}&nbsp;
                    Available: {unlockedAssets} {product.vaultAsset} </div>

                <button disabled={isRequestButtonDisabled} onClick={requestWithdrawalFunction.write}
                        className="w-full py-4 text-sm font-semibold text-white bg-[#203746] rounded-lg  mt-4 transition-all duration-300 ease-in-out   hover:bg-[#36566ba0] ">
                    {requestButtonText}
                </button>

                {isCancelEnabled &&
                    <button disabled={isCancelButtonDisabled} onClick={cancelWithdrawalFunction.write}
                            className="w-full py-4 text-sm font-semibold text-white bg-[#203746] rounded-lg  mt-4 transition-all duration-300 ease-in-out   hover:bg-[#36566ba0] ">
                        {cancelButtonText}
                    </button>
                }

                {isWithdrawalEnabled &&
                    <button disabled={isWithdrawButtonDisabled} onClick={withdrawFunction.write}
                            className="w-full py-4 text-sm font-semibold text-white bg-[#203746] rounded-lg  mt-4 transition-all duration-300 ease-in-out   hover:bg-[#36566ba0] ">
                        {withdrawButtonText}
                    </button>
                }
            </div>
        </>
    );
}

export default WithdrawTab;

import React, { useRef, useEffect } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler
);

const data = {
  labels: [
    "3 Nov",
    "5 Nov",
    "07 Nov",
    "9 Nov",
    "11 Nov",
    "13 Nov",
    "15 Nov",
    "17 Nov",
    "19 Nov",
  ],
  datasets: [
    {
      label: "share price",
      data: [1.15, 1.17, 1.12, 1.1, 1.13, 1.1, 1.16, 1.19, 1.16],
      fill: true,
      backgroundColor: "rgba(206, 19, 18, 0.06)",
      borderColor: "#CE1312",
      lineTension: 0.5,
    },
  ],
};
const options = {
  plugins: {
    drawPerpendicularLine: {},
  },
  scales: {
    x: {
      grid: {
        display: false, // Hide vertical gridlines
      },
    },
    y: {
      grid: {
        color: "rgba(120, 144, 156, 1)", // Set color for horizontal gridlines
      },
      beginAtZero: true,
    },
  },
};

const PerfChart = ({product}) => {
  return (
    <>
      <div className="border-2  border-[#2F4858] p-5  bg-primary rounded-lg flex flex-col ">
        <div className="flex sm:flex-row flex-col justify-between items-center gap-5 pb-5">
          <p className="flex md:text-md text-sm text-[#78909C] gap-x-5 font-bold">
            Vault Share Price <span className="text-white"> 1.184 ${product.asset}</span>
          </p>
          <div className="flex gap-x-5 ">
            <p className=" flex  text-sm text-[#78909C] gap-x-2 font-[275] ">
              <span className="text-red font-bold"> -1.30%</span> Change
            </p>{" "}
            <p className=" flex  text-sm text-[#78909C] gap-x-2 font-[275] ">
              <span className="text-green font-bold">+18.35%</span>
              Cumulative
            </p>
          </div>
        </div>
        <div className="sm:h-[300px] cart-w-set">
          <Line data={data} options={options} />
        </div>
      </div>
    </>
  );
};
export default PerfChart;

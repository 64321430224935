import React from "react";
import { Link } from "react-router-dom";
function ProductCard({ obj }) {
  const fortmatNumber = (num) => num ? Intl.NumberFormat("en-US",{  maximumFractionDigits: 2   }).format(num) : 0

  return (
    <>
      <div className=" lg:w-[25%] md:w-1/3 sm:w-1/2 w-full p-3 ">
        <Link to={`/vaults/${obj.productId}`} className="">
          <div className="border-2  group transition-all duration-300 ease-in-out hover:border-[#246993] border-[#2F4858] xl:p-5 p-3  bg-primary rounded-lg flex flex-col">
            <div className="flex  xl:gap-x-3 md:gap-x-2 gap-4  whitespace-nowrap justify-center items-center mb-5 xl:mb-7">
              <div className="w-[40px] h-[40px] rounded-full overflow-hidden ">
                <img
                  src={obj.imageURL}
                  alt="img"
                  className="w-full min-w-[40px] min-h-[40px]"
                />
              </div>
              <p className="font-bold text-sm text-lightwhite">
                {obj.asset}
              </p>
              <button className="bg-[#274050] py-[6px] px-3 rounded-[100px] text-[13px] text-white">
                {obj.name}
              </button>
            </div>
            <p className="font-black xl:text-llg text-lg text-white opacity-80 roboto flex gap-x-2 items-end justify-center ">
              {obj.apy}
              <span className="text-xs  font-thin k2d mb-1">APY </span>
            </p>
            <div className="flex flex-col my-6 xl:my-8 xl:px-6">
              <p className="flex justify-between text-[13px] text-white opacity-80">
                <span className="font-[275]  "> Pool Size</span>
                <span className="font-semibold">{fortmatNumber(obj.value)} $</span>
              </p>
              <div className="rounded-md bg-[#284150] bg-opacity-80 my-2 ">
                <div className="w-3/4 h-[8px] red-grident overflow-hidden rounded-md "></div>
              </div>
              <p className="flex justify-between text-[13px] text-white opacity-80">
                <span className="font-[275]"> Vault Capacity </span>
                <span className="font-semibold">{fortmatNumber(obj.capacity)} $</span>
              </p>
            </div>

            <button className="text-center text-sm font-medium text-white  text py-2 rounded-[12px] btn transition-all duration-300 ease-in-out ">
              {" "}
              Deposit
            </button>
          </div>
        </Link>
      </div>
    </>
  );
}

export default ProductCard;

import React from "react";
import {useState} from "react";
import {usePrepareContractWrite, useContractWrite, useContractRead, useWaitForTransaction} from 'wagmi'
import {vaultContractAddresses, assetAddresses, vaultAbi, erc20Abi} from "../../config";
import {useDebounce} from '../../hooks/useDebounce';
import {parseEther, formatEther} from 'viem'

function DepositTab({product, network, address, refresh}) {
    // the amount of assets that will be deposited
    const [depositAmount, setDepositAmount] = useState('')
    const debouncedDepositAmount = useDebounce(depositAmount, 500)
    // the amount of assets that are approved to be deposited (ERC20 approve function)
    const [approved, setApproved] = useState(0)
    const vaultContractAddress = network ? vaultContractAddresses[network][product.productId] : undefined;
    const assetContractAddress = network ? assetAddresses[network][product.asset] : undefined;

    const isApprovalNeeded = Boolean(Number(depositAmount)) && (approved < Number(depositAmount));
    const isDepositEnabled = Boolean(Number(depositAmount)) && (approved >= Number(depositAmount));

    // calls ERC20.allowance(address owner, address spender)
    useContractRead({
        address: assetContractAddress,
        abi: erc20Abi,
        functionName: 'allowance',
        args: [address, vaultContractAddress],
        enabled: assetContractAddress,
        onSuccess(data) {
            const approvedAmount = formatEther(data);
            console.log('Assets already approved:', approvedAmount)
            setApproved(Number(approvedAmount));
        },
        onError(error) {
            console.log('Error on reading asset ERC20 allowance', error)
        }
    })

    const preparedDepositFunction = usePrepareContractWrite({
        address: vaultContractAddress,
        abi: vaultAbi,
        functionName: 'deposit',
        enabled: vaultContractAddress && isDepositEnabled,
        args: [parseEther(debouncedDepositAmount), address],
        onSuccess(data) {
            console.log('Deposit result: ', data);
        },
        onError(error) {
            console.log('Error on asset deposit', error)
        }
    });
    const depositFunction = useContractWrite(preparedDepositFunction.config);
    const {isLoading: isDepositing, isSuccess: isDeposited} = useWaitForTransaction({
        hash: depositFunction.data?.hash,
    });

    const preparedAssetApproveFunction = usePrepareContractWrite({
        address: assetContractAddress,
        abi: erc20Abi,
        functionName: 'approve',
        enabled: assetContractAddress && isApprovalNeeded,
        args: [vaultContractAddress, parseEther(debouncedDepositAmount)],
        onSuccess(data) {
            console.log('Approval result: ', data);
        },
        onError(error) {
            console.log('Error on ERC20 approval', error)
        }
    });
    const assetApproveFunction = useContractWrite(preparedAssetApproveFunction.config);
    const {isLoading: isApproving, isSuccess: isApproved} = useWaitForTransaction({
        hash: assetApproveFunction.data?.hash,
    });

    let depositButtonText = 'Deposit';
    let isDepositButtonDisabled = !depositAmount || Number(depositAmount) === 0;
    if (isApproving) {
        depositButtonText = 'Approving...';
        isDepositButtonDisabled = true;
    } else if (isDepositing) {
        depositButtonText = 'Depositing...';
        isDepositButtonDisabled = true;
    } else if (isApprovalNeeded) {
        depositButtonText = 'Approve';
    } else if (isApproved) {
        depositButtonText = 'Approved';
    } else if (isDeposited) {
        depositButtonText = 'Deposited';
        //refresh(assetApproveFunction.data?.hash);
    }
    let handleDeposit;
    if (isDepositEnabled) {
        console.log(`Deposit is enabled. amount ${debouncedDepositAmount}, Approved amount ${approved}`);
    } else {
        console.log(`Deposit is not enabled. amount ${debouncedDepositAmount}, Approved amount ${approved}`);
    }
    if (isApprovalNeeded) {
        console.log(`Approval is needed.`);
        handleDeposit = assetApproveFunction.write;
    } else {
        console.log(`Approval is not needed.`);
        handleDeposit = depositFunction.write;
    }

    return (
        <>
            <div className="sm:mx-10 mx-5 mb-6 py-6">
                <div className="flex flex-row items-center">
                    <input
                        type="text"
                        className="text-md px-6 py-3 font-bold text-white opacity-80  text-end w-full rounded-lg border border-[#203746] bg-[#02121D] placeholder:text-white placeholder:opacity-80"
                        placeholder={'0.00'}
                        value={depositAmount}
                        onChange={(e) => setDepositAmount(e.target.value)}
                    />
                    <div className="text-end text-sm text-white pt-1 opacity-80 font-bold px-3">
                        {product.vaultAsset}
                    </div>
                </div>
                <button disabled={isDepositButtonDisabled} onClick={handleDeposit}
                        className="w-full py-4 text-sm font-semibold text-white bg-[#203746] rounded-lg  mt-4 transition-all duration-300 ease-in-out hover:bg-[#36566ba0] ">
                    {depositButtonText}
                </button>
            </div>
        </>
    );
}

export default DepositTab;

import React from "react";
import NavLinks from "./NavLinks";

const Sidebar = ({ setSidebarShow, isSidebarShow }) => {
  return (
    <>
      <div
        className={`fixed top-0  w-[250px] h-screen bg-primary shadow-xl transition-all duration-300 ease-in z-20 ${
          isSidebarShow ? "left-0" : "-left-full"
        }`}
      >
        <span
          className="absolute right-3 top-8 cursor-pointer"
          onClick={() => setSidebarShow(false)}
        >
          <svg
            width="32"
            height="32"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.25 4.75L4.75 11.25M4.75 4.75L11.25 11.25"
              stroke="#14A6D5"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>

        <div className="pt-20">
          <NavLinks />
        </div>
      </div>
    </>
  );
};

export default Sidebar;

import BitCoin from "../components/assets/img/bitcoin.png";
import Eth from "../components/assets/img/eth.png";
import USDC from "../components/assets/img/usdc.png";

export const strategies = [
    {value: "covered-call", label: "Covered Call"},
    {value: "covered-call-spread", label: "Covered Call Spread"},
    {value: "collar", label: "Collar"},
    {value: "rwa-yield", label: "RWA Yield"},
    {value: "hedged-staking", label: "Heged Staking"}
];
export const assets = [
    {value: "BTC", label: "Bitcoin"},
    {value: "ETH", label: "Ether"},
    {value: "USDC", label: "USDC"}
];
export const productList = [
    'btc-covered-call',
    'btc-collar',
    'btc-covered-call-spread',
    'eth-covered-call',
    'eth-collar',
    'eth-covered-call-spread',
    'eth-hedged-staking',
    'usd-rwa-yield',
];

export const products = {
    'btc-covered-call': {
        productId: 'btc-covered-call',
        imageURL: BitCoin,
        asset: "BTC",
        vaultAsset: "WBTC",
        apy: "16.7%",
        value: "3620561",
        capacity: "10000000",
        name: "Covered Call",
        description: "This is a strategy combining the purchase of a digital asset (e.g. Bitcoin) and selling out of the money call options to sell that asset. Typically an investor would deposit tokens into a vault and it will accrue yield from the options premium. Weekly European Call Options are sold every Friday at an auction. The strike price is selected in order to achieve a delta of less than 0.2. This is on order to minimise the likelihood of the options expiring at the money.",
        facts: [
            "best in bearish to mild bull markets",
            "In a bearish scenario, the investor loses less than from just holding the asset (he receives the option premium)",
            "In a bullish scenario, the investor forgives part of the upside from just holding the asset."
        ],
        risks: [
            "If the price of the asset declines too much, the investor will incur potential losses, that may not be covered by the profit from option premiums",
            "If the price of the asset goes up and reaches the option strike price, the investor will be forced to sell it, and therefore incur an opportunity cost from not keeping the asset",
            "Operational and Execution Risks",
            "Third party protocol risks"
        ]
    },
    'btc-covered-call-spread': {
        productId: 'btc-covered-call-spread',
        imageURL: BitCoin,
        asset: "BTC",
        vaultAsset: "WBTC",
        apy: "19.7%",
        value: "2620561",
        capacity: "10000000",
        name: "Covered Call Spread",
        description: "Selling covered Calls is a great strategy to generate yield from the collection of option premiums. However, if the underlying asset price increases above the strike price, the investor is giving up any upside. If a call option is purchased, it can offer profit from price appreciation. This type of strategy is typically called a vertical spread. Buying and selling the same option at the same time at different strike prices. Since the goal of the vault strategy is to generate yield, the difference between the premium collected for selling a call option and the premium paid to buy the second call needs to be positive. That means that the option that is bought needs to be even further out of the money than the options sold.",
        facts: [
            "Lower yield than covered calls but higher upside in a bull market.",
            "In a bearish scenario, the investor loses less than from just holding the asset (he receives the option premium).",
            "In a mildly bullish scenario, the investor forgives part of the upside from just holding the asset.",
            "In a bullish scenario the investor still benefits from the upside"
        ],
        risks: [
            "If the price of the asset declines too much, the investor will incur potential losses, that may not be covered by the profit from option premiums.",
            "Operational and execution risks",
            "Third party protocol risks"
        ]
    },
    'btc-collar': {
        productId: 'btc-collar',
        imageURL: BitCoin,
        asset: "BTC",
        vaultAsset: "WBTC",
        apy: "10.7%",
        value: "2620561",
        capacity: "10000000",
        name: "Collar",
        description: "This strategy combines a holding in a digital asset (e.g., Bitcoin) with the simultaneous sale of an out-of-the-money (OTM) call option and purchase of an OTM put option on the same asset and with the same expiration. Typically, an investor would deposit their digital assets into a vault and accrue a small yield periodically from the net option premiums (the price of the call net of the price of the put). Their downside is significantly reduced (by the put strike plus the net premium received) if their assets’ price declines. Their upside is limited by the strike price of the call option (typically 10%) for the duration of each contract (typically 1 week). The protocol will automatically trade options subject to pre-defined rules (min yield, min strike) and based on market conditions.",
        facts: [
            "Investors who are willing to retain some exposure to their digital assets in the medium/long term but worry about significant permanent loss of capital while also like generating some current yield.",
            "Investors who believe in the gradual price appreciation of their digital assets in the long term, but not necessarily in an imminent strong rally."
        ],
        risks: [
            "The price of the digital assets may decline. The investor will incur losses, until the protective put kicks in, which may not be covered by the profit from the net option premium. In any declining market, if options are traded by the vault, the investor will always be better off than if they simply held on to their assets.",
            "If the price of the assets is above the call strike price on the expiry of the contract (e.g., at the end of the week), the investor will miss out on any additional upside beyond the strike price. A portion of their asset worth the additional upside will be automatically sold to settle the expiring call option (in this case the put will naturally expire unexercised).",
            "Operational and Execution Risks",
            "Third party protocol risks"
        ]
    },
    'eth-covered-call': {
        productId: 'eth-covered-call',
        imageURL: Eth,
        asset: "ETH",
        vaultAsset: "ETH",
        apy: "20.9%",
        value: "1914307",
        capacity: "10000000",
        name: "Covered Call",
        description: "This is a strategy combining the purchase of a digital asset (e.g. Bitcoin) and selling out of the money call options to sell that asset. Typically an investor would deposit tokens into a vault and it will accrue yield from the options premium. Weekly European Call Options are sold every Friday at an auction. The strike price is selected in order to achieve a delta of less than 0.2. This is on order to minimise the likelihood of the options expiring at the money.",
        facts: [
            "best in bearish to mild bull markets",
            "In a bearish scenario, the investor loses less than from just holding the asset (he receives the option premium)",
            "In a bullish scenario, the investor forgives part of the upside from just holding the asset."
        ],
        risks: [
            "If the price of the asset declines too much, the investor will incur potential losses, that may not be covered by the profit from option premiums",
            "If the price of the asset goes up and reaches the option strike price, the investor will be forced to sell it, and therefore incur an opportunity cost from not keeping the asset",
            "Operational and Execution Risks",
            "Third party protocol risks"
        ]
    },
    'eth-covered-call-spread': {
        productId: 'eth-covered-call-spread',
        imageURL: Eth,
        asset: "ETH",
        vaultAsset: "ETH",
        apy: "20.9%",
        value: "1914307",
        capacity: "10000000",
        name: "Covered Call",
        description: "This is a strategy combining the purchase of a digital asset (e.g. Bitcoin) and selling out of the money call options to sell that asset. Typically an investor would deposit tokens into a vault and it will accrue yield from the options premium. Weekly European Call Options are sold every Friday at an auction. The strike price is selected in order to achieve a delta of less than 0.2. This is on order to minimise the likelihood of the options expiring at the money.",
        facts: [
            "best in bearish to mild bull markets",
            "In a bearish scenario, the investor loses less than from just holding the asset (he receives the option premium)",
            "In a bullish scenario, the investor forgives part of the upside from just holding the asset."
        ],
        risks: [
            "If the price of the asset declines too much, the investor will incur potential losses, that may not be covered by the profit from option premiums",
            "If the price of the asset goes up and reaches the option strike price, the investor will be forced to sell it, and therefore incur an opportunity cost from not keeping the asset",
            "Operational and Execution Risks",
            "Third party protocol risks"
        ]
    },
    'eth-collar': {
        productId: 'eth-collar',
        imageURL: Eth,
        asset: "ETH",
        vaultAsset: "ETH",
        apy: "10.7%",
        value: "2620561",
        capacity: "10000000",
        name: "Collar",
        description: "This strategy combines a holding in a digital asset (e.g., Bitcoin) with the simultaneous sale of an out-of-the-money (OTM) call option and purchase of an OTM put option on the same asset and with the same expiration. Typically, an investor would deposit their digital assets into a vault and accrue a small yield periodically from the net option premiums (the price of the call net of the price of the put). Their downside is significantly reduced (by the put strike plus the net premium received) if their assets’ price declines. Their upside is limited by the strike price of the call option (typically 10%) for the duration of each contract (typically 1 week). The protocol will automatically trade options subject to pre-defined rules (min yield, min strike) and based on market conditions.",
        facts: [
            "Investors who are willing to retain some exposure to their digital assets in the medium/long term but worry about significant permanent loss of capital while also like generating some current yield.",
            "Investors who believe in the gradual price appreciation of their digital assets in the long term, but not necessarily in an imminent strong rally."
        ],
        risks: [
            "The price of the digital assets may decline. The investor will incur losses, until the protective put kicks in, which may not be covered by the profit from the net option premium. In any declining market, if options are traded by the vault, the investor will always be better off than if they simply held on to their assets.",
            "If the price of the assets is above the call strike price on the expiry of the contract (e.g., at the end of the week), the investor will miss out on any additional upside beyond the strike price. A portion of their asset worth the additional upside will be automatically sold to settle the expiring call option (in this case the put will naturally expire unexercised).",
            "Operational and Execution Risks",
            "Third party protocol risks"
        ]
    },
    'eth-hedged-staking': {
        productId: 'eth-hedged-staking',
        imageURL: Eth,
        asset: "ETH",
        vaultAsset: "USDC",
        apy: "5%",
        value: "1914307",
        capacity: "10000000",
        name: "Market Neutral Liquid Staking",
        description: "Liquid staking is one of the largest markets in DEFI. It offers staking returns to investors while alleviating the constraints related to staking: lock-in periods, minimal amounts, and most importantly the need to provide and manage hardware. Liquid staking protocols such as Lido Finance, allows anyone to stake ETH or other assets (Solana, Matic) and receive “staked tokens” as a deposit receipt. The staked tokens are exposed to the price changes of the underlying asset. By offering a hedge against the asset price change, this strategy effectively offers a fixed income product.",
        facts: [
            "Fixed income with alternative risk profile (source of income is from PoS networks)",
            "Cheaper execution obtained by batching of trades. An individual trader will need to execute many trades to achieve the same portfolio."
        ],
        risks: [
            "Operational and Execution Risks",
            "Third party protocol risks",
            "Market risks that can result in bad hedging"
        ]
    },
    'usd-rwa-yield': {
        productId: 'usd-rwa-yield',
        imageURL: USDC,
        asset: "USDC",
        vaultAsset: "USDC",
        apy: "5%",
        value: "1914307",
        capacity: "10000000",
        name: "RWA Yield",
        description: "Real World Assets (RWA) tokenisation is coming of age. More and more protocols offer tokenised RWA and tokenised loans and even T bills. This vault is an index vault investing in a basket of tokenised fixed income products trying to hedge risk by rebalancing assets and using derivatives when those are available (eg interest swap, credit default swaps).",
        facts: [
            "Risk reduced fixed income",
            "Cheaper execution obtained by batching of trades. An individual trader will need to execute many trades to achieve the same portfolio.",
            "Risk management and hedging"
        ],
        risks: [
            "Credit Default",
            "Interest rate risks",
            "Operational and Execution Risks",
            "Third party protocol risks"
        ]
    }
};


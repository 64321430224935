import { Link } from "react-router-dom";
import { LogoIcon } from "./Icon";
import NavLinks from "./NavLinks";
import { ConnectKitButton } from "connectkit";

function Navbar({ setSidebarShow }) {
  return (
    <div className="my-4 lg:my-6 xl:my-10 flex flex-col">
      <div className="  px-3 sm:px-4 lg:px-5 2xl:px-[70px]">
        <div className="flex flex-row justify-between">
          <div>
            <Link to="https://upyield.finance/" className="flex gap-x-1 items-center">
              <LogoIcon />{" "}
              <span className="sm:text-md text-sm  font-bold jura text-gray ">
                {" "}
                UPYIELD FINANCE DEMO
              </span>
            </Link>
          </div>
          <span className="hidden lg:block">
            <NavLinks />
          </span>

          <ConnectKitButton />

          <div className="flex items-center gap-x-5">
            {" "}
            <span
              className="lg:hidden cursor-pointer"
              onClick={() => setSidebarShow(true)}
            >
              <svg
                width="30"
                height="30"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5 17H19M5 12H19M5 7H19"
                  stroke="#14A6D5"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
          </div>

        </div>
      </div>
    </div>
  );
}

export default Navbar;

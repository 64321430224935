import React from "react";
import { Link, useLocation } from "react-router-dom";

function NavLinks() {
  const location = useLocation();
  return (
    <>
      <div className="flex items-center gap-x-5 xl:gap-x-12   flex-col lg:flex-row  lg:gap-x-8 justify-center h-full gap-y-10 lg:gap-y-0 px-3 text-center navlinks">
        <Link
          to="https://upyield.finance"
          className={` text-sm  px-2 relative text-white   transition-all duration-300 ease-in-out  hover:opacity-100  
          ${
            location.pathname === "/home"
              ? "active opacity-100 after:absolute after:w-full after:h-1 after:left-0 after:-bottom-2 after:contents-[] bg-gredient "
              : "opacity-50"
          }
          `}
        >
          Home
        </Link>
        <Link
          to="/portfolio"
          className={` text-sm  px-2 relative text-white  transition-all duration-300 ease-in-out  hover:opacity-100  
          ${
            location.pathname === "/portfolio"
              ? "active opacity-100 after:absolute after:w-full after:h-1 after:left-0 after:-bottom-2 after:contents-[] bg-gredient"
              : "opacity-50"
          }
          `}
        >
          Portfolio
        </Link>
        <Link
          to="/"
          className={`text-sm  px-2 relative text-white  transition-all duration-300 ease-in-out  hover:opacity-100 ${
              location.pathname === "/"
              ? "active opacity-100 after:absolute after:w-full after:h-1 after:left-0 after:-bottom-2 after:contents-[] bg-gredient"
              : " opacity-50"
          } `}
        >
          Vaults
        </Link>
        <Link
          to="/analytics"
          className={` text-sm  px-2 relative text-white   transition-all duration-300 ease-in-out  hover:opacity-100  
          ${
            location.pathname === "/analytics"
              ? "active opacity-100 after:absolute after:w-full after:h-1 after:left-0 after:-bottom-2 after:contents-[] bg-gredient"
              : "opacity-50"
          }
          `}
        >
          Analytics
        </Link>
      </div>
    </>
  );
}

export default NavLinks;

export const LogoIcon = () => {
  return (
    <>
      <svg
        width="39"
        height="45"
        viewBox="0 0 39 45"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M29.4466 17.5127C29.1492 17.5127 28.8518 17.3427 28.7244 17.0876C28.4694 16.6626 28.6394 16.1525 29.0218 15.94L37.7316 10.9242C38.1564 10.7117 38.6663 10.8392 38.8787 11.2217C39.1336 11.6468 38.9637 12.1569 38.5813 12.3694L29.8715 17.4277C29.7016 17.4702 29.5741 17.5127 29.4466 17.5127Z"
          fill="url(#paint0_linear_728_2188)"
        />
        <path
          d="M19.5048 43.9518C19.0375 43.9518 18.6551 43.5692 18.6551 43.1016V34.1328C18.6551 33.6652 19.0375 33.2826 19.5048 33.2826C19.9722 33.2826 20.3546 33.6652 20.3546 34.1328V43.1016C20.3546 43.5692 19.9722 43.9518 19.5048 43.9518Z"
          fill="url(#paint1_linear_728_2188)"
        />
        <path
          d="M9.52039 17.4702C9.39293 17.4702 9.22298 17.4277 9.09552 17.3427L0.428205 12.2844C0.00333645 12.0294 -0.124124 11.5193 0.130797 11.1367C0.385718 10.7117 0.89556 10.5841 1.27794 10.8392L9.98774 15.8974C10.4126 16.1525 10.5401 16.6626 10.2851 17.0451C10.1152 17.3002 9.81779 17.4702 9.52039 17.4702Z"
          fill="url(#paint2_linear_728_2188)"
        />
        <path
          d="M9.52039 17.4702C9.39293 17.4702 9.22298 17.4277 9.09552 17.3427L0.428205 12.2844C0.00333645 12.0294 -0.124124 11.5193 0.130797 11.1367C0.385718 10.7117 0.89556 10.5841 1.27794 10.8392L9.98774 15.8974C10.4126 16.1525 10.5401 16.6626 10.2851 17.0451C10.1152 17.3002 9.81779 17.4702 9.52039 17.4702Z"
          fill="url(#paint3_linear_728_2188)"
        />
        <path
          d="M29.4466 17.5127C29.1492 17.5127 28.8518 17.3427 28.7244 17.0876C28.4694 16.6626 28.6394 16.1525 29.0218 15.94L37.7316 10.9667C38.1564 10.7542 38.6663 10.8817 38.8787 11.2642C39.1336 11.6893 38.9637 12.1994 38.5813 12.4119L29.8715 17.4277C29.744 17.5127 29.5741 17.5127 29.4466 17.5127Z"
          fill="url(#paint4_linear_728_2188)"
        />
        <path
          d="M19.5047 34.6854C19.2073 34.6854 18.9099 34.5154 18.7825 34.2603C18.57 33.8353 18.6975 33.3252 19.0799 33.1126L28.5969 27.6718V16.7051C28.5969 16.2375 28.9793 15.855 29.4466 15.855C29.914 15.855 30.2964 16.2375 30.2964 16.7051V28.0969C30.2964 28.1394 30.2964 28.1394 30.2964 28.1819C30.2964 28.4794 30.1264 28.777 29.8715 28.9045L19.9296 34.6004C19.7596 34.6429 19.6322 34.6854 19.5047 34.6854Z"
          fill="url(#paint5_linear_728_2188)"
        />
        <path
          d="M19.5048 34.6854C19.3773 34.6854 19.2074 34.6429 19.0799 34.5579L9.09552 28.8195C8.8406 28.6494 8.67065 28.3944 8.67065 28.0969V16.6201C8.67065 16.1525 9.05304 15.77 9.52039 15.77C9.98775 15.77 10.3701 16.1525 10.3701 16.6201V27.5868L19.8872 33.1126C20.312 33.3677 20.4395 33.8778 20.1846 34.2603C20.0571 34.5154 19.7597 34.6854 19.5048 34.6854Z"
          fill="url(#paint6_linear_728_2188)"
        />
        <path
          d="M29.4467 17.5127C29.3192 17.5127 29.1493 17.4702 29.0218 17.3852L19.4623 11.9018L9.94522 17.3427C9.52035 17.5552 9.01051 17.4277 8.79808 17.0451C8.58564 16.6201 8.7131 16.11 9.09548 15.8975L19.0374 10.2016C19.2923 10.0316 19.6322 10.0316 19.8871 10.2016L29.8291 15.9825C30.2539 16.2375 30.3814 16.7476 30.1265 17.1302C29.999 17.3852 29.7441 17.5127 29.4467 17.5127Z"
          fill="url(#paint7_linear_728_2188)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.6249 17.0304C28.3708 16.6067 28.5402 16.0984 28.9213 15.8866L36.3316 11.6501L19.3938 1.82166L2.54067 11.523L9.95095 15.8018C10.2347 15.9721 10.3853 16.2565 10.3774 16.5403C10.3817 16.6942 10.3397 16.8495 10.2474 16.9881C9.9933 17.4117 9.48516 17.5388 9.10406 17.2846L1.69378 13.0058V32.4933L18.5892 42.2795V33.7643C18.5892 33.2983 18.9703 32.917 19.4361 32.917C19.7711 32.917 20.0622 33.1141 20.1994 33.3982C20.4774 33.5365 20.6701 33.8277 20.6701 34.1625V42.705L37.3299 32.8875V13.3375L30.023 17.63C29.6472 17.885 29.1461 17.7575 28.8956 17.3325C28.8917 17.3266 28.8879 17.3206 28.8842 17.3146C28.7737 17.2422 28.6821 17.1449 28.6249 17.0304ZM38.8184 11.3255C38.7492 11.1504 38.6177 11.0003 38.4488 10.8876L19.8596 0.127092C19.6055 -0.0423641 19.2667 -0.0423641 19.0127 0.127092L0.509365 10.7535C0.480052 10.7679 0.451516 10.7842 0.423973 10.8026L0.423445 10.8029C0.169378 10.9299 0 11.2265 0 11.523V11.5231V32.9594C0 33.2559 0.169378 33.5101 0.423445 33.6795L19.0127 44.44C19.0229 44.4469 19.0334 44.4534 19.0442 44.4597C19.1096 44.6556 19.2434 44.8264 19.4175 44.915C19.4524 44.9239 19.4854 44.9327 19.517 44.9412C19.6368 44.9734 19.736 45 19.8351 45C19.9603 45 20.1273 44.9575 20.2526 44.8725L38.5825 34.0775C38.833 33.9075 39 33.6525 39 33.355V11.85C39 11.6605 38.9323 11.4711 38.8184 11.3255Z"
          fill="url(#paint8_linear_728_2188)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.4872 23.3586L9.94534 28.8194C9.81788 28.9044 9.69042 28.9044 9.56296 28.9044C9.26555 28.9044 8.96814 28.7344 8.84068 28.4794C8.62824 28.0543 8.75571 27.5442 9.13809 27.3317L18.6551 21.9025V10.9242C18.6551 10.4566 19.0375 10.0741 19.5048 10.0741C19.9722 10.0741 20.3546 10.4566 20.3546 10.9242V21.9236L29.8716 27.4167C30.2965 27.6718 30.424 28.1819 30.1691 28.5644C29.9991 28.8195 29.7442 28.9895 29.4468 28.9895C29.3193 28.9895 29.1494 28.947 29.0219 28.862L19.4872 23.3586Z"
          fill="url(#paint9_linear_728_2188)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_728_2188"
            x1="28.6031"
            y1="17.5127"
            x2="38.6896"
            y2="17.5127"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#711FDA" />
            <stop offset="1" stopColor="#2989A8" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_728_2188"
            x1="18.6551"
            y1="43.9518"
            x2="20.3038"
            y2="43.9518"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#711FDA" />
            <stop offset="1" stopColor="#2989A8" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_728_2188"
            x1="0"
            y1="17.4702"
            x2="10.105"
            y2="17.4702"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#711FDA" />
            <stop offset="1" stopColor="#2989A8" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_728_2188"
            x1="0"
            y1="17.4702"
            x2="10.105"
            y2="17.4702"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#711FDA" />
            <stop offset="1" stopColor="#2989A8" />
          </linearGradient>
          <linearGradient
            id="paint4_linear_728_2188"
            x1="28.6031"
            y1="17.5127"
            x2="38.6896"
            y2="17.5127"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#711FDA" />
            <stop offset="1" stopColor="#2989A8" />
          </linearGradient>
          <linearGradient
            id="paint5_linear_728_2188"
            x1="18.6801"
            y1="34.6854"
            x2="29.9496"
            y2="34.6854"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#711FDA" />
            <stop offset="1" stopColor="#2989A8" />
          </linearGradient>
          <linearGradient
            id="paint6_linear_728_2188"
            x1="8.67065"
            y1="34.6854"
            x2="19.9678"
            y2="34.6854"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#711FDA" />
            <stop offset="1" stopColor="#2989A8" />
          </linearGradient>
          <linearGradient
            id="paint7_linear_728_2188"
            x1="8.69574"
            y1="17.5127"
            x2="29.6136"
            y2="17.5127"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#711FDA" />
            <stop offset="1" stopColor="#2989A8" />
          </linearGradient>
          <linearGradient
            id="paint8_linear_728_2188"
            x1="0"
            y1="45"
            x2="37.8358"
            y2="45"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#711FDA" />
            <stop offset="1" stopColor="#2989A8" />
          </linearGradient>
          <linearGradient
            id="paint9_linear_728_2188"
            x1="8.73834"
            y1="28.9895"
            x2="29.6562"
            y2="28.9895"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#711FDA" />
            <stop offset="1" stopColor="#2989A8" />
          </linearGradient>
        </defs>
      </svg>
    </>
  );
};

export const BackIcon = () => (
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="17" cy="17" r="16.5" stroke="#668598" />
    <path
      d="M21 24.0025L14.8192 17.5L21 10.9975L19.0972 9L11 17.5L19.0972 26L21 24.0025Z"
      fill="#668598"
    />
  </svg>
);

export const erc20Abi = require('./abis/MockERC20Token.json').abi;
export const vaultAbi = require('./abis/MockAssetVault.json').abi;

export const vaultContractAddresses = {
    80001 : { // polygon-mumbai
        'btc-covered-call': '0x27815A2B7F1F24d6F1FEf8C0Ec1029d21930Af1b',
        'eth-covered-call': '0xb32caaF1BB5d9Ac6f689B39200a6c7FEE073D87c',
        'btc-collar': '0x27815A2B7F1F24d6F1FEf8C0Ec1029d21930Af1b',
        'btc-covered-call-spread': '0x27815A2B7F1F24d6F1FEf8C0Ec1029d21930Af1b',
        'eth-collar': '0xb32caaF1BB5d9Ac6f689B39200a6c7FEE073D87c',
        'eth-covered-call-spread': '0xb32caaF1BB5d9Ac6f689B39200a6c7FEE073D87c',
        'eth-hedged-staking': '0xb32caaF1BB5d9Ac6f689B39200a6c7FEE073D87c',
        'usd-rwa-yield': '0x1A4d94fF8d2aF30dd40220942b46dbF7E697e5b9'
    },
    421613 : { // arbitrum-goerli
        'btc-covered-call': '0x78f5b07675fAbb29a007F58ab6E4C6F9e769da8f',
        'eth-covered-call': '0x90D6c35E110769e4402a12b45873796990D53232',
        'btc-collar': '0x78f5b07675fAbb29a007F58ab6E4C6F9e769da8f',
        'btc-covered-call-spread': '0x78f5b07675fAbb29a007F58ab6E4C6F9e769da8f',
        'eth-collar': '0x90D6c35E110769e4402a12b45873796990D53232',
        'eth-covered-call-spread': '0x90D6c35E110769e4402a12b45873796990D53232',
        'eth-hedged-staking': '0x90D6c35E110769e4402a12b45873796990D53232',
        'usd-rwa-yield': '0xd91d4ee3f689c798CE7ccfc0CA5B3Dc0e2b4BfCb'
    }
}

export const assetAddresses = {
    80001 : { // polygon-mumbai
        'BTC': '0x201a6B17491C369750133492D1Ea3b2c957b30A8',
        'ETH': '0x9De02E1C387884e6854F274458AA57417497810d',
        'USDC': '0x98884132F328564987b608Da707d004dc2388EB5'
    },
    421613 : { // arbitrum-goerli
        'BTC': '0x6818416a3fbb33A1315fC7125685C0BaeDfb0691',
        'ETH': '0xfFcEd1889cE08Af5E6be0c725D30CcB2feAD8aFC',
        'USDC': '0xda187C3dbB0f40048474D4efEBC2d3388bf64B96'
    }
}

import React, { useState } from "react";
import Select from "react-select";
import ProductCard from "../vault/ProductCard";
import {products,productList,strategies,assets} from "../../data/products";

function Vaults() {
  const [selectedStrategy, setSelectedStrategy] = useState(null);
  const [selectedAsset, setSelectedAsset] = useState(null);

  return (
    <>
      <div className="max-w-[1280px] mx-auto md:my-18 xl:my-24 my-10 px-3 sm:px-4 lg:px-5">
        <div className="flex flex-wrap justify-between ">
          <p className="text-xl  text-white opacity-80  font-bold items-center volkhov my-2">
            Vaults - Demo
          </p>
          <div className="flex items-center gap-x-6 my-2">
            <div className="bg-[#274050] fillter-select   flex items-center gap-x-3 rounded-[20px] px-2 ">
              <div>
                <Select
                  className="  max-w-[150px]"
                  defaultValue={selectedStrategy}
                  onChange={setSelectedStrategy}
                  options={strategies}
                  placeholder="Strategy"
                />
              </div>
            </div>{" "}
            <div className="bg-[#274050] fillter-select   flex items-center gap-x-3 rounded-[20px] px-2 ">
              <div>
                <Select
                  className="  max-w-[150px]"
                  defaultValue={selectedAsset}
                  onChange={setSelectedAsset}
                  options={assets}
                  placeholder="All assets"
                />
              </div>
            </div>{" "}
          </div>
        </div>
        <div className="mt-8 flex flex-wrap sm:justify-between justify-center">
          {ProductCard &&
            productList.map((obj, index) => (
              <ProductCard obj={products[obj]} key={index} />
            ))}
        </div>
      </div>
    </>
  );
}

export default Vaults;

import React from "react";
import { useState } from "react";
import {products} from "../../data/products";
import DepositPanel from "../vault/DepositPanel";
import PerfChart from "../vault/PerfChart";
import { BackIcon } from "../common/Icon";
import { useNavigate, useParams } from "react-router-dom";
import { useAccount, useNetwork, useContractRead } from 'wagmi'
import {vaultContractAddresses,vaultAbi} from "../../config";
import {formatEther} from "viem";

const fortmatNumber = (num) => num ? Intl.NumberFormat("en-US",{  maximumFractionDigits: 2   }).format(num) : 0;
function VaultDetails() {
  const navigate = useNavigate();
  const { productId } = useParams();
  const [userBalance, setUserBalance] = useState('')
  const [, setRefresh] = useState('')
  const product = products[productId];

  const { address } = useAccount();
  const { chain } = useNetwork()
  console.log(`address: ${address}, chain: `,chain);

  const chainId = chain?.id;
  const vaultContractAddress = chainId? vaultContractAddresses[chain.id][product.productId] : undefined;
  useContractRead({
    address: vaultContractAddress,
    abi: vaultAbi,
    functionName: 'balanceOf',
    args: [address],
    cacheTime: 2_000,
    enabled: address,
    onSuccess(data) {
      const balance = formatEther(data);
      console.log('Read userBalance: ', balance);
      setUserBalance(balance);
    },
    onError(error) {
      console.log('Error on reading user balance', error)
    }
  })

  return (
    <>
      <div className="max-w-[1280px] flex flex-col space-y-8 mx-auto 0 px-3 sm:px-4 lg:px-5">
        <div className="flex">
          <div
            onClick={() => navigate(-1)}
            className="flex cursor-pointer items-center space-x-3"
          >
            <BackIcon />{" "}
            <span className="text-[16px] font-bold text-white">All Vaults</span>
          </div>
        </div>
        <div className="flex justify-between my-2 flex-wrap items-center">
          <div className="flex items-center gap-x-4 py-2">
            <div className="w-[49px] h-[49px] rounded-full overflow-hidden ">
              <img src={product.imageURL} alt="{product.imageURL}" className="w-full" />
            </div>
            <p className="font-bold text-lg  lg:text-llg text-white opacity-80 volkhov">
              {product.asset}
            </p>
          </div>
          <div className="flex flex-row items-center py-2 ">
            <div className="flex  flex-col sm:px-5 px-3">
              <p className="text-xs font-light ubuntu text-[#78909C] mb-1">
                My Balance
              </p>
              <p className="text-sm  font-bold  ubuntu text-white mb-1">
                {userBalance} shares
              </p>
            </div>{" "}
            <div className="flex  flex-col sm:px-5 px-3 border-x border-[#78909C]">
              <p className="text-xs font-light ubuntu text-[#78909C] mb-1">
                Strategy
              </p>
              <p className="text-sm  font-bold  ubuntu text-white mb-1">
                {product.name}
              </p>
            </div>{" "}
            <div className="flex  flex-col sm:px-5 px-3">
              <p className="text-xs font-light ubuntu text-[#78909C] mb-1">
                Projected APY
              </p>
              <p className="text-sm  font-bold  ubuntu text-white mb-1">
                26.49%
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-col  py-4  lg:py-10">
          <div className="flex flex-wrap  lg:flex-nowrap   gap-6">
            <div className="border-2  border-[#2F4858] py-6 sm:px-10 px-4  bg-primary rounded-lg flex flex-col w-full">
              <div className="flex flex-col my-2 ">
                <p className="flex justify-between text-[13px] text-white opacity-80 mb-2">
                  <span className="font-[275]  "> Pool Size</span>
                  <span className="font-semibold text-md">{fortmatNumber(product.value)} {product.vaultAsset} </span>
                </p>
                <div className="rounded-md bg-[#284150] bg-opacity-80 my-2 ">
                  <div className="w-3/4 h-[8px] red-grident overflow-hidden rounded-md "></div>
                </div>
                <p className="flex justify-between text-[13px] text-white opacity-80 mt-2">
                  <span className="font-[275]"> Vault Capacity </span>
                  <span className="font-semibold">{fortmatNumber(product.capacity)} {product.vaultAsset} </span>
                </p>
              </div>{" "}
              <div className="flex flex-row justify-between items-center pt-6 lg:pt-10">
                <div className="flex  flex-col ">
                  <p className="text-sm font-[100]   text-white opacity-80 mb-1">
                    Next Epoch in:
                  </p>
                  <p className="sm:text-md text-sm roboto  font-bold   text-white opacity-80 mb-1">
                    04d:09m:12s
                  </p>
                </div>{" "}
                <div className="flex  flex-col ">
                  <p className="text-sm font-[200]   text-white opacity-80 mb-1">
                    {product.asset} Price
                  </p>
                  <p className="sm:text-md text-sm roboto  font-bold   text-white opacity-80 mb-1">

                  </p>
                </div>{" "}
                <div className="flex  flex-col  ">
                  <p className="text-sm font-[200]   text-white opacity-80 mb-1">
                    Strike Price
                  </p>
                  <p className="sm:text-md text-sm roboto  font-bold   text-white opacity-80 mb-1">
                    $16.79
                  </p>
                </div>{" "}
              </div>
            </div>{" "}

            <DepositPanel product={product} network={chainId} address={address} refresh={setRefresh} />
          </div>
        </div>

        <PerfChart product={product}/>

        <div className="py-12 flex flex-wrap  ">
          <div className=" lg:w-[55%] w-full my-4 lg:pr-4">
            <div className="mb-10">
              <p className="text-white text-md lg:text-lg font-bold mb-5 ">
                Product Description
              </p>
              <p className="text-[#B5BDC1] opacity-80 text-[17px] lg:text-md    ">
                {product.description}
              </p>
            </div>
            <div className="mb-10">
              <p className="text-white text-md lg:text-lg font-bold mb-5 ">
                Risks
              </p>
              <p className="text-[#B5BDC1] opacity-80 text-[17px] lg:text-md    ">
                UpYield vault strategies are sophisticated investment products that
                carry risks and are not suitable for investors who do not
                comprehend the product or are risk averse. <br /><br />
              </p>
              <ul className="list-disc ml-4 ">
                {product.risks.map((obj,index) => (
                    <li key={`risk-${index}`} className="text-[#B5BDC1] opacity-80 text-[17px] lg:text-md">
                      {obj}
                    </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="lg:w-[45%] w-full my-4 lg:pl-4">
            <div className="  bg-primary rounded-lg p-6 flex flex-col w-full">
              <p className="text-[#B5BDC1]  text-md lg:text-lg font-black mb-5 ">
                Investment Summary
              </p>
              <ul className="list-disc ml-4 ">
                {product.facts.map((obj, index) => (
                  <li key={`fact-${index}`} className="text-[#B5BDC1] opacity-80 text-[17px] lg:text-md">
                    {obj}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default VaultDetails;
